$(function() {
	if ($(window).width() > 1200) {
		$('.nav__link_dropdown').mouseover(function () {
			$('.nav__link_dropdown').not(this).removeClass('nav__dropdown_active').next('.nav__dropdown').slideUp(0);
			$(this).addClass('nav__dropdown_active').next('.nav__dropdown').slideDown(0);
		});

		$('.js-header').mouseleave(function () {
			$('.nav__dropdown').slideUp(0);
			$('.nav__link_dropdown').removeClass('nav__dropdown_active');
		});

		$('.nav__link:not(".nav__link_dropdown")').mouseover(function () {
			$('.nav__link_dropdown').removeClass('nav__dropdown_active').next().slideUp(0);
		});
	} else if ($(window).width() <= 768) {
		$('.nav__sublist').click(function () {
			$(this).find('.nav__dropdown').addClass('nav__dropdown_active');
		});

		$('.js-nav-link-car').click(function (e) {
			e.preventDefault();
			e.stopPropagation();
			let data = $(this).data('nav-car');
			$('.js-nav-dropdown-car[data-nav-car=' + data + ']').addClass('nav__dropdown-group_active');
		});

		$('.nav__dropdown-prev').click(function (e) {
			e.preventDefault();
			e.stopPropagation();
			$(this).closest('.nav__dropdown').removeClass('nav__dropdown_active');
			$('.js-nav-dropdown-car').removeClass('nav__dropdown-group_active');
		});
	}
	
})